export default function useContent () {
  /**
   * 태그를 추가합니다.
   * @param tagList 태그 목록
   * @param newTag 추가할 태그
   * @returns 새로운 태그 목록
   */
  const addTag = (tagList: string[], newTag: string) => {
    if (!tagList.includes(newTag)) {
      return [...tagList, newTag]
    }

    return [...tagList]
  }

  /**
   * 태그를 삭제합니다..
   * @param tagList 태그 목록
   * @param deleteTag 삭제할 태그
   * @returns 새로운 태그 목록
   */
  const removeTag = (tagList: string[], deleteTag: string) => {
    return tagList.filter((tag: string) => tag !== deleteTag)
  }

  /**
   * Layout 타입을 설정합니다.
   * @param type Layout 타입
   */
  const setLayout = (type: string) => {
    const headerEl = document.querySelector('#header')
    const footerEl = document.querySelector('#footer')
    if (headerEl) {
      headerEl.classList.add(type)
    }
    if (footerEl) {
      footerEl.classList.add(type)
    }
  }

  /**
   * Layout 타입을 제거합니다.
   * @param type Layout 타입
   */
  const removeLayout = (type: string) => {
    const headerEl = document.querySelector('#header')
    const footerEl = document.querySelector('#footer')
    if (headerEl) {
      headerEl.classList.remove(type)
    }
    if (footerEl) {
      footerEl.classList.remove(type)
    }
  }

  /**
   * Layout 타입을 모두 제거합니다.
   */
  const clearLayout = () => {
    const headerEl = document.querySelector('#header')
    const footerEl = document.querySelector('#footer')
    if (headerEl) {
      headerEl.className = ''
    }
    if (footerEl) {
      footerEl.className = ''
    }
  }

  return {
    addTag,
    removeTag,
    setLayout,
    removeLayout,
    clearLayout
  }
}
